import React, { useState } from "react";
import CookieConsentComponent from "react-cookie-consent";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import privacyPolicy from "../downloads/polityka-prywatnosci.pdf"; 

export default function CookieConsent() {
  const [consent, setConsent] = useState(false);
  const location = useLocation();

  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              text
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { text } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "cookiesConsent";
  }).node;

  const hotjarScript = (<Helmet
    script={[{ 
        type: 'text/javascript', 
        innerHTML: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2254776,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    }]} 
  />);

  return (
    <>
      {consent ? (hotjarScript) :
       (<CookieConsentComponent
          location="bottom"
          buttonText="Ok"
          cookieName="gdpr-consent"
          style={{ background: "#2B373B", padding: "1rem", alignItems: "initial" }}
          buttonStyle={{ color: "white", backgroundColor: "#01AB55", fontSize: "15px", width: "10vw", margin: "15px" }}
          expires={150}
          onAccept={() => {
            initializeAndTrack(location);
            setConsent(true);
          }}
        >
          {text} <a style={{color: "white"}} href={privacyPolicy} download>Polityka prywatności.</a>
        </CookieConsentComponent>)
      }
    </>
  );
}
