import React from "react";
import styles from "./button.module.css"

export default function Button({text, color, isCto}) {
  const colorClassName = color === "white" ? styles.white : styles.green;
  const ctoClass = isCto ? styles.cto : "";

  return (
    <button className={`${colorClassName} ${styles.button} ${ctoClass}`}>{text}</button>
  );
}
