import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import privacyPolicy from "../downloads/polityka-prywatnosci.pdf"; 
import styles from "./footer.module.css";

export default function Footer() {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              rights
              author
              links {
                url
                text
              }
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { rights, author } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "footer"
  }).node;

  const links = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "navigation"
  }).node.links;

  return (
    <footer>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div dangerouslySetInnerHTML={{__html: rights}}/>
          <div className={styles.links}>
            {links.map((link)=>(<a key={link.url} href={link.url}>{link.text}</a>))}
            <a style={{color: "black"}} href={privacyPolicy} download>Polityka prywatności</a>
          </div>
        </div>
        <div>
          {author}
        </div>
      </div>
    </footer>
  );
}
