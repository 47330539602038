import React from "react";
import logo from "./logo_small.svg";
import Button from "./button";
import styles from "./header.module.css";

export default function Header({showResults}) {
  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <a className={styles.homepageLink} href="/"><img className={`${styles.logo} ${styles.wrapper}`} src={logo} alt="Logo" /></a>
        {showResults && (
          <div className={styles.wrapper}>
            <a role="button" aria-label="Pokaż wyniki" href="/wyniki"><Button color="white" text="Zobacz wyniki" /></a>
          </div>)}
      </div>
    </header>
  );
}
